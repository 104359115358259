
.spinner {
  /* border: 5px solid #eee;
  border-top: 5px solid rgb(170, 169, 169); */
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .delay {

} */

.gear {
  animation: roll-gear 2s ease-in 500ms 1 normal forwards;
  /* animation: spin 2s linear 2s 1 normal forwards; */
  /* width: 100%; */
}

.underline {
  width: 0px;
  animation: roll 2s ease-in 500ms 1 normal forwards;
}

@keyframes roll-gear {
  0% {
    /* transform: translateX(0%); */
    transform: rotate(0deg);
    display: block;
    /* left: 0%; */
  }
  100% {
    /* transform: translateX(100%); */
    transform: rotate(360deg);
    left: 95%;
  }
}
@keyframes roll {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}


.bg-overlay {
  background: linear-gradient(to bottom, rgba(81, 117, 131, 0.3) 0%, rgba(0,0,0,0.9) 100%);
  backdrop-filter: blur(2px);
}

.blur-filter {
  /* background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.7) 100%); */
  /* backdrop-filter: blur(4px); */
}

.centered-div {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.remove-scroll-bar::-webkit-scrollbar {
  display: none;
}
